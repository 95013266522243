
<!--<section class="pb-5 ">-->

<!--  <div class="d-flex justify-content-between align-items-center bg-purple-light-no-linear" style="height: 100vh">-->
<!--    &lt;!&ndash;      <img class="d-block order-lg-2 me-lg-n5 flex-shrink-0" src="" alt="Collection">&ndash;&gt;-->
<!--    <div class="container my-n5  " >-->
<!--      <div class="row ">-->
<!--        &lt;!&ndash;        <div class="col-md-6">&ndash;&gt;-->
<!--        &lt;!&ndash;          <img class="img-fluid" src="assets/background/20201031_095449.jpg"/>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        &lt;!&ndash;        <div class="col-md-6">&ndash;&gt;-->
<!--        &lt;!&ndash;          <img class="img-fluid" src="assets/background/20201031_095449.jpg"/>&ndash;&gt;-->
<!--        &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="col-md-7 py-5 align-text-bottom text-center text-lg-end">-->
<!--          <p class=" pt-5 fs-4 text-white" >We are big company specialized in retail vendors</p>-->
<!--          <p class=" fs-4 text-white" >We are glad to see you asap from you</p>-->
<!--          <p class=" fs-4 text-white" >We are big company</p>-->
<!--          <p class=" fs-4 text-white" >We are big company</p>-->
<!--          <button mat-fab extended color="" class="align-self-baseline w-50 fs-5 " style="color: rgb(11, 74, 114)">Discover more</button>-->
<!--          &lt;!&ndash;          <img class="img-fluid" src="assets/background/20201031_095449.jpg"/>&ndash;&gt;-->
<!--        </div>-->

<!--        <div class="col-md-5 text-end">-->
<!--          <img class="img-fluid rounded-5" src="assets/cover/Monovero-cover-6-blue.png"/>-->
<!--        </div>-->

<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
<!--</section>-->


<section class="py-5 ">
  <div class="pt-3" >
    <div class="d-flex bg-green-light-no-linear" style="min-height: 300px">
      <!--      <img class="d-block order-lg-2 me-lg-n5 flex-shrink-0" src="" alt="Collection">-->
      <div class="container   ">
        <div class="row ">
          <div class="col-md-7 text-center text-lg-end">
<!--            <div class="d-flex flex-column align-items-end  text-end ">-->
<!--              <h2 class="mt-5 fw-light  " style="color: #008296;">-->
<!--                <span i18n>Has just arrived!</span></h2>-->
<!--              <h2 class=" display-5  " style="color: #008296" ><span i18n>Summer Collection</span></h2>-->
<!--              <p class="  pb-3 " style="color: #008296" >-->
<!--                <span i18n>T-shirts &amp; much more... See different articles</span></p>-->
<!--&lt;!&ndash;              <button mat-raised-button routerLink="/items">Shop Now</button>&ndash;&gt;-->
<!--              <button mat-fab extended routerLink="/items" color=""-->
<!--                      class="fs-5" style="color: rgb(11, 74, 114)" i18n>Shop Now-->
<!--              </button>-->
<!--            </div>-->

            <div class="d-flex flex-column align-items-end  text-end ">
              <h2 class="mt-5 fw-light  " style="color: #008296;">
                <span i18n>Clothes and accessories for every occasion</span></h2>
              <h2 class=" display-5  " style="color: #008296" >
                <span i18n>Fashion, style and more</span>
              </h2>
              <div class="pb-5 pt-3 " style="color: #008296" >
                <span i18n>
                  Browse our offerings: from casual outfits to elegant accessories.<br/>
                  Fashion for every moment and every mood.</span></div>
<!--              <div class=" pb-5 " style="color: #008296"><span>bring retailers together</span></div>-->
              <!--              <button mat-raised-button routerLink="/items">Shop Now</button>-->
              <button mat-fab extended routerLink="/items" color=""
                      class="fs-5" style="color: rgb(11, 74, 114)" i18n>Shop Now
              </button>
              <button mat-fab extended routerLink="/about-seller" color="primary"
                      class="my-3 fs-5" i18n>For Partners
              </button>
            </div>

          </div>
          <div class="col-md-5 d-flex justify-content-between my-n5">
            <div class="w-100 bg-purple-light-1  rounded-5" style=" min-height: 450px;">
              <img class="img-fluid rounded-5" src="assets/cover/Monovero-cover-6-blue.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<!-- Quick View Modal-->
<!--<section class="container position-relative pt-3 pt-lg-0 py-5 home-page-header-overlap mt-lg-n10 ">-->
<!--  <div class="row">-->
<!--    <div class="col-md-12 col-lg-12">-->
<!--      <div class="card rounded-5">-->
<!--        <div class=" ">-->

<!--          <div class="row ">-->
<!--            <div class="col-md-4">-->
<!--              <a class="d-block text-center text-decoration-none text-muted "-->
<!--                 [routerLink]="['/items/women']">-->
<!--                <div class="">-->
<!--                  <mat-card>-->
<!--                    <img class="img-fluid rounded" src="assets/images/dogImage.jpg">-->
<!--                    <mat-card-header>-->
<!--                      <mat-card-subtitle>-->
<!--                        &lt;!&ndash;                  <label class="text-break">Woman</label>&ndash;&gt;-->
<!--                        <h5 class=" w-100  ">Women</h5>-->
<!--                      </mat-card-subtitle>-->
<!--                    </mat-card-header>-->
<!--                  </mat-card>-->
<!--                </div>-->
<!--                &lt;!&ndash;            <div class="card  rounded" >&ndash;&gt;-->
<!--                &lt;!&ndash;              <img class="img-fluid rounded" src="assets/images/woman-5.webp" >&ndash;&gt;-->
<!--                &lt;!&ndash;              <div class="card-body">&ndash;&gt;-->
<!--                &lt;!&ndash;                &lt;!&ndash;                                    <img class="" src="{{itemsTshirt[0].itemVariations[0].fileInfos[0].url}}" >&ndash;&gt;&ndash;&gt;-->

<!--                &lt;!&ndash;                <h5 class="card-title ">Woman</h5>&ndash;&gt;-->
<!--                &lt;!&ndash;                &lt;!&ndash;                      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;                &lt;!&ndash;                      <a href="#" class="btn btn-primary">Go somewhere</a>&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                &lt;!&ndash;            </div>&ndash;&gt;-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="col-md-4">-->
<!--              <a class=" d-block text-center text-decoration-none text-muted"-->
<!--                 [routerLink]="['/items/man']">-->
<!--                <mat-card>-->
<!--                  <img class="img-fluid rounded" src="assets/images/avatar_me.jpg">-->
<!--                  <mat-card-header>-->
<!--                    <mat-card-subtitle>-->
<!--                      <h5 class="   ">Men</h5>-->
<!--                      &lt;!&ndash;                    <label class="text-break">Men</label>&ndash;&gt;-->
<!--                    </mat-card-subtitle>-->
<!--                  </mat-card-header>-->
<!--                </mat-card>-->
<!--              </a>-->
<!--            </div>-->
<!--            <div class="col-md-4">-->
<!--              <a class="d-block text-center text-decoration-none text-muted "-->
<!--                 [routerLink]="['/items/kid']">-->
<!--                <div class="">-->
<!--                  <mat-card>-->
<!--                    <img class="img-fluid rounded" src="assets/images/kids-5.webp">-->
<!--                    <mat-card-header>-->
<!--                      <mat-card-subtitle>-->
<!--                        &lt;!&ndash;                  <label class="text-break">Kids</label>&ndash;&gt;-->
<!--                        <h5 class=" w-100  ">Kids</h5>-->
<!--                      </mat-card-subtitle>-->
<!--                    </mat-card-header>-->
<!--                  </mat-card>-->
<!--                </div>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->


<section class="tns-carousel tns-controls-lg">
  <div class="col-lg-12 ">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="true">

    </div>
  </div>
</section>


<!-- Products grid (Trending products)-->
<br/>
<!--<section class="container py-5">-->
<!--  <h2 class="h3 text-center text-muted">Trending products</h2>-->
<!--  <div class="row pt-4 mx-n2">-->
<!--    &lt;!&ndash; Product&ndash;&gt;-->
<!--    <div class="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">-->

<!--      <hr class="d-sm-none">-->
<!--    </div>-->

<!--  </div>-->
<!--  <div class="text-center pt-3">-->
<!--    <button mat-stroked-button color="primary"-->
<!--            routerLink="/items">-->
<!--      More products-->
<!--    </button>-->
<!--  </div>-->
<!--</section>-->





<!--<section *ngIf="itemsJeans && itemsJeans.length" class="container py-3">-->
<!--  <h2 class="h3 text-center text-muted">Jeans</h2>-->

<!--  <div id="carouselControlsProductsSecond" class="carousel slide " data-bs-ride="carousel">-->
<!--    <div class="carousel-inner" >-->
<!--      <ng-container *ngFor="let counter of itemsJeans; let counterIdx = index; let first = first;">-->

<!--        <div class="carousel-item " *ngIf="addElementOnEveryCircle(counterIdx)" [ngClass]="{'active': first}">-->
<!--          <div class="cards-wrapper">-->

<!--            <div *ngFor="let item of itemsJeans.slice(counterIdx, counterIdx + elementsPerViewInCarousel); let homeIdx = index"-->
<!--                 class="card " >-->
<!--              <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]">-->
<!--                <img src="{{item.itemVariations[0].fileInfos[0].url}}"-->
<!--                     class="img-fluid rounded">-->
<!--              </a>-->
<!--              <div class="card-body d-flex align-items-end">-->
<!--                <a class="product-item-title  ">{{item.translations[0].brandName}}</a> <br/>-->
<!--                <h7 class="product-title ">-->
<!--                  <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]"-->
<!--                     class="">-->
<!--                    {{item.translations[0].title}}-->
<!--                  </a>-->
<!--                </h7>-->
<!--                <div class="d-block">-->
<!--                  <span *ngFor="let size of item.itemVariations[0].itemSizeFormArray; let sizeIndx = index;"-->
<!--                        class="size-container-price ">-->
<!--                    <mat-label class="fw-normal text-muted">{{size.size.value}}</mat-label>-->
<!--                    <span *ngIf="sizeIndx < (item.itemVariations[0].itemSizeFormArray.length - 1)">, &nbsp;</span>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->
<!--    </div>-->

<!--    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControlsProductsSecond"-->
<!--            data-bs-slide="prev">-->
<!--      <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--      <span class="visually-hidden">Previous</span>-->
<!--    </button>-->
<!--    <button class="carousel-control-next" type="button" data-bs-target="#carouselControlsProductsSecond"-->
<!--            data-bs-slide="next">-->
<!--      <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--      <span class="visually-hidden">Next</span>-->
<!--    </button>-->
<!--  </div>-->

<!--</section>-->






<!--TODO not delete - round image for brands-->
<!--<section class="container py-5">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-md-3">-->
<!--        <div class="row justify-content-center align-items-center">-->
<!--          <div class="col-sm-12 text-center">-->
<!--            <img class="img-fluid rounded-circle" style="max-width: 75px"-->
<!--                 src="https://images.prismic.io/rakuten-europe/bbeab264-93e0-454d-98c0-649a178a01f1_nike.jpg?auto=compress,format">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="row">-->
<!--          <div class="col-sm-12 ">-->
<!--            <img class="img-fluid rounded-circle" style="max-width: 50px"-->
<!--                 src="https://images.prismic.io/rakuten-europe/ec538032-b102-43ce-ad87-f1c7c5e4052f_asos-plc-square-logo.jpg?auto=compress,format">-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div class="col-md-3">-->
<!--&lt;!&ndash;        <div class="row justify-content-center align-items-center">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-sm-12 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="img-fluid rounded-circle" style="max-width: 50px"&ndash;&gt;-->
<!--&lt;!&ndash;                 src="https://images.prismic.io/rakuten-europe/bbeab264-93e0-454d-98c0-649a178a01f1_nike.jpg?auto=compress,format">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="col-sm-12 text-start">-->
<!--          <div class="p-5">-->
<!--            <img class="img-fluid rounded-circle" style="max-width: 100px"-->
<!--                 src="https://images.prismic.io/rakuten-europe/ec538032-b102-43ce-ad87-f1c7c5e4052f_asos-plc-square-logo.jpg?auto=compress,format">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--&lt;!&ndash;      <div class="col-md-3">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;        <img class="img-fluid rounded-circle" style="max-width: 75px"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;             src="https://images.prismic.io/rakuten-europe/45ecba22-84b7-4289-975f-09d419a992c3_booking-es.jpg?auto=compress,format">&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;        <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="img-fluid rounded-circle" style="max-width: 50px"&ndash;&gt;-->
<!--&lt;!&ndash;                 src="https://images.prismic.io/rakuten-europe/ec538032-b102-43ce-ad87-f1c7c5e4052f_asos-plc-square-logo.jpg?auto=compress,format">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="row justify-content-end align-items-end">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-sm-12 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="img-fluid rounded-circle" style="max-width: 100px"&ndash;&gt;-->
<!--&lt;!&ndash;                 src="https://images.prismic.io/rakuten-europe/45ecba22-84b7-4289-975f-09d419a992c3_booking-es.jpg?auto=compress,format">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="col-md-3">&ndash;&gt;-->
<!--&lt;!&ndash;        &lt;!&ndash;        <img class="img-fluid rounded-circle" style="max-width: 75px"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;        &lt;!&ndash;             src="https://images.prismic.io/rakuten-europe/45ecba22-84b7-4289-975f-09d419a992c3_booking-es.jpg?auto=compress,format">&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;        <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-sm-12">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="img-fluid rounded-circle" style="max-width: 50px"&ndash;&gt;-->
<!--&lt;!&ndash;                 src="https://images.prismic.io/rakuten-europe/ec538032-b102-43ce-ad87-f1c7c5e4052f_asos-plc-square-logo.jpg?auto=compress,format">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="row justify-content-end align-items-end">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="col-sm-12 text-center">&ndash;&gt;-->
<!--&lt;!&ndash;            <img class="img-fluid rounded-circle" style="max-width: 100px"&ndash;&gt;-->
<!--&lt;!&ndash;                 src="https://images.prismic.io/rakuten-europe/45ecba22-84b7-4289-975f-09d419a992c3_booking-es.jpg?auto=compress,format">&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->

<!--  </div>-->

<!--</section>-->

<section class="" >
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-md-8 mb-4">
        <div class="d-sm-flex justify-content-between align-items-center bg-purple-light-1 overflow-hidden rounded-3">
          <div class="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">

<!--            <h4 class="fw-light "><span i18n>For</span></h4>-->
            <h3 class="mb-4"><span i18n>Clothes, shoes and more...</span></h3>
            <div class="d-grid gap-3 d-md-flex justify-content-md-between ">
              <button mat-raised-button class="flex-fill"
                      routerLink="/items/w-blouse">
                <span i18n>Women blouses</span>
              </button>
              <button mat-raised-button class="flex-fill"
                      routerLink="/items/m-shirt">
                <span i18n>Men shirts</span>
              </button>
            </div>

            <!--            <button mat-raised-button routerLink="/items/trainers" class="w-50">Girls</button>-->
            <!--            <button mat-raised-button routerLink="/items/trainers" class="w-50">Boys</button>-->
          </div>

          <img class="img-fluid  " style="max-height: 300px; "
               src="assets/images/hidefumi-ohmichi-gxfLSqIZFng-unsplash.jpg"
               alt="Shop Converse">


        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="d-flex flex-column h-100 justify-content-center  rounded-3" >
          <div class="py-4 my-2 px-4 text-center">
            <div class="py-1">
              <!--              Everyday new products-->
              <h5 class="mb-2"><span i18n>Accessories</span></h5>
              <p class="fw-normal text-muted"><span i18n>&</span></p>
              <button mat-raised-button color="primary"
                      routerLink="/items/accessories">
                <span i18n>Discover more</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<section *ngIf="womenTshirts && womenTshirts.length" class="container py-3">
  <div class="row ">
<!--  <h2 class="h3 text-center text-muted">Trainers</h2>-->

  <div id="carouselControlsProductsWomen" class=" carousel slide " data-bs-ride="carousel">
    <div class=" carousel-inner" >

      <ng-container *ngFor="let counter of womenTshirts; let counterIdx = index; let first = first;">

        <div class="carousel-item " *ngIf="addElementOnEveryCircle(counterIdx)" [ngClass]="{'active': first}">
          <div class="cards-wrapper">

            <div *ngFor="let item of womenTshirts.slice(counterIdx, counterIdx + elementsPerViewInCarousel); let homeIdx = index"
                 class="card col-md-2 h-100 product-card" >
              <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]">
                <img src="{{item.itemVariations[0].fileInfos[0].url}}"
                     class="card-img-top">
              </a>
              <div class="card-body  d-flex align-items-end">
                <div>
                  <a class="product-item-title d-block ">{{item.brand.name}}</a>
                  <h7 class="product-title ">
                    <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]"
                       class="">
                      {{item.translations[0].title}}
                    </a>
                  </h7>
                </div>
<!--                <div class="d-block">-->
<!--                  <span *ngFor="let size of item.itemVariations[0].itemSizeFormArray; let sizeIndx = index;"-->
<!--                        class="size-container-price ">-->
<!--                    <mat-label class="fw-normal text-muted">{{size.size.value}}</mat-label>-->
<!--                    <span *ngIf="sizeIndx < (item.itemVariations[0].itemSizeFormArray.length - 1)">, &nbsp;</span>-->
<!--                  </span>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

<!--    <button class="carousel-control-prev" type="button" data-bs-target="#carouselControlsProductsWomen"-->
<!--            data-bs-slide="prev">-->
<!--      <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--      <span class="visually-hidden">Previous</span>-->
<!--    </button>-->
<!--    <button class="carousel-control-next" type="button" data-bs-target="#carouselControlsProductsWomen"-->
<!--            data-bs-slide="next">-->
<!--      <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--      <span class="visually-hidden">Next</span>-->
<!--    </button>-->
  </div>

  </div>
</section>


<section *ngIf="menTshirts && menTshirts.length" class="container py-3">
  <div class="row ">
    <!--  <h2 class="h3 text-center text-muted">Trainers</h2>-->

    <div id="carouselControlsProductsMen" class=" carousel slide " data-bs-ride="carousel">
      <div class=" carousel-inner" >

        <ng-container *ngFor="let counter of menTshirts; let counterIdx = index; let first = first;">

          <div class="carousel-item " *ngIf="addElementOnEveryCircle(counterIdx)" [ngClass]="{'active': first}">
            <div class="cards-wrapper">

              <div *ngFor="let item of menTshirts.slice(counterIdx, counterIdx + elementsPerViewInCarousel); let homeIdx = index"
                   class="card col-md-2 h-100 product-card" >
                <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]">
                  <img src="{{item.itemVariations[0].fileInfos[0].url}}"
                       class="card-img-top">
                </a>
                <div class="card-body  d-flex align-items-end">
                  <div>
                    <a class="product-item-title d-block ">{{item.brand.name}}</a>
                    <h7 class="product-title ">
                      <a [routerLink]="['/item-details', item.id, item.itemVariations[0].selectedItemColor.name]"
                         class="">
                        {{item.translations[0].title}}
                      </a>
                    </h7>
                  </div>
                  <!--                <div class="d-block">-->
                  <!--                  <span *ngFor="let size of item.itemVariations[0].itemSizeFormArray; let sizeIndx = index;"-->
                  <!--                        class="size-container-price ">-->
                  <!--                    <mat-label class="fw-normal text-muted">{{size.size.value}}</mat-label>-->
                  <!--                    <span *ngIf="sizeIndx < (item.itemVariations[0].itemSizeFormArray.length - 1)">, &nbsp;</span>-->
                  <!--                  </span>-->
                  <!--                </div>-->
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

<!--      <button class="carousel-control-prev" type="button" data-bs-target="#carouselControlsProductsMen"-->
<!--              data-bs-slide="prev">-->
<!--        <span class="carousel-control-prev-icon" aria-hidden="true"></span>-->
<!--        <span class="visually-hidden">Previous</span>-->
<!--      </button>-->
<!--      <button class="carousel-control-next" type="button" data-bs-target="#carouselControlsProductsMen"-->
<!--              data-bs-slide="next">-->
<!--        <span class="carousel-control-next-icon" aria-hidden="true"></span>-->
<!--        <span class="visually-hidden">Next</span>-->
<!--      </button>-->
    </div>

  </div>
</section>


<section class="" *ngIf="itemTrainers && itemTrainers.length" >
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-md-8 mb-4">
        <div class="d-sm-flex justify-content-between align-items-center bg-purple-light-1 overflow-hidden rounded-3">
          <div class="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">

            <h4 class="fw-light ">For</h4>
            <h3 class="mb-4">Clothes, shoes and more...</h3>
            <div class="d-grid gap-3 d-md-flex justify-content-md-between ">
              <button mat-raised-button class="flex-fill"
                      routerLink="/items/girls-t-shirt">
                <span i18n>Girls</span>
              </button>
              <button mat-raised-button class="flex-fill"
                      routerLink="/items/boys-t-shirt">
                <span i18n>Boys</span>
              </button>
            </div>

<!--            <button mat-raised-button routerLink="/items/trainers" class="w-50">Girls</button>-->
<!--            <button mat-raised-button routerLink="/items/trainers" class="w-50">Boys</button>-->
          </div>

            <img class="img-fluid  " style="max-height: 250px"
                 src="{{menTshirts[0].itemVariations[0].fileInfos[0].url}}"
                 alt="Shop Converse">

        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="d-flex flex-column h-100 justify-content-center  rounded-3" >
          <div class="py-4 my-2 px-4 text-center">
            <div class="py-1">
<!--              Everyday new products-->
              <h5 class="mb-2">Accessories and fun</h5>
              <p class="fw-normal text-muted">Look up</p>
              <button mat-raised-button color="primary"
                      routerLink="/items/accessories">Discover more</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>




<section *ngIf="stores && stores.length" class="container pb-lg-5 pt-lg-3">
<!--    <h2 class="h3 text-center py-3 text-muted">Stores</h2>-->
<!--  <div class="py-3 text-center">-->
<!--    <button mat-stroked-button color="primary"-->
<!--            routerLink="/stores/all">-->
<!--      Stores-->
<!--    </button>-->
<!--    <br/>-->
<!--  </div>-->
  <div class=" text-center">
    <button mat-button routerLink="/stores/all" color="primary" class="text-decoration-underline py-5">
      <h3 class=" " i18n>Stores</h3>
    </button>
    <br/>
  </div>

  <div class="row g-3 justify-content-evenly pb-sm-3">
    <div *ngFor="let store of this.stores"
         class="col-md-3 col-sm-4 col-6 ">
<!--      <button routerLink="{{'/store/' + store.name}}"-->
<!--              mat-fab extended color=""-->
<!--              class=" py-3 py-sm-4 w-100 h-100 ">-->
<!--        <span class="text-primary">{{store.name}}</span>-->
<!--                <img class="d-block mx-auto" src="assets/logo/monovero-logo-5.png" style="width: 150px;" alt="Brand">-->
<!--      </button>-->
      <button routerLink="{{'/store/' + store.name}}"
              mat-button color="primary"
              class="shadow-sm py-3 w-100 h-100 ">
        <h4 class=" " i18n>{{store.name}}</h4>
        <!--        <img class="d-block mx-auto" src="img/shop/brands/01.png" style="width: 150px;" alt="Brand">-->
      </button>
    </div>

  </div>


</section>



<section class="bg-purple-light-no-linear py-sm-5 " >
  <div class="container pt-lg-3">
    <h2 i18n class="h3 mb-3 pb-4 text-light text-center">Why our marketplace?</h2>
    <div class="row pt-lg-2 d-inline-flex align-items-center justify-content-center">
      <div class="col-lg-3 col-sm-6 ">
        <div class="d-inline-flex align-items-center text-start">
          <mat-icon class="icon-color-white pe-5" >account_balance_wallet</mat-icon>
          <div class="">
            <h6 i18n class="text-light  mb-1">Cash payment</h6>
            <p i18n class="text-light  opacity-50 ">upon receipt of the order</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 ">
        <div class="d-inline-flex align-items-center text-start ">
          <mat-icon class="icon-color-white pe-5">local_shipping</mat-icon>
          <div class="">
            <h6 i18n class="text-light  mb-1">Free shipping & return</h6>
            <p i18n class="text-light  opacity-50 ">for orders over {{freeOrderLimit | currency}}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 ">
        <div class="d-inline-flex align-items-center text-start">
          <mat-icon class="icon-color-white " >mark_email_read</mat-icon>
          <div class="">
            <h6 i18n class="text-light  mb-1">Customer Support</h6>
            <p class="text-light  opacity-50 ">support&#64;monochrome.bg</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 ">
        <div class="d-inline-flex align-items-center text-start">
          <mat-icon class="icon-color-white" >lock</mat-icon>
          <div class="">
            <h6 i18n class="text-light  mb-1">Secure</h6>
            <p i18n class="text-light  opacity-50 ">SSL / Secure certificate</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="container ">
  <div class="row ">
    <div class="col-md-6 text-center ">
      <div class="d-flex h-100 flex-column align-items-center justify-content-center" >
        <!--        <div class="text-center">-->
        <!--          <div class="py-1">-->
        <!--            <h5 class="mb-2 text-muted">We're on the Google Cloud.</h5>-->
        <!--            <h5 class="my-3 text-muted">Are you a retailer looking for a next-gen digital commerce platform?</h5>-->
        <!--            <h5 class="my-3 text-muted"> If so, you have landed at the right place!</h5>-->
        <h5 i18n class="my-3 text-muted">Monochrome</h5>
        <h5 i18n class="my-3 text-muted">E-commerce platform for clothing, shoes and fashion accessories.</h5>
        <h5 i18n class="my-3 text-muted ">It was created to connect and unite Customers and Merchants, allowing them to reach a wider range of opportunities.</h5>
<!--        <button i18n mat-raised-button color="primary" class="my-4"-->
<!--                routerLink="/guide/cloud-solution">Craft the brand you want...</button>-->
        <!--            <h5 class=" text-muted">Powered by Google Cloud.</h5>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="col-md-6">
      <img class="img-fluid" src="assets/images/nordwood-themes-eGHlWS-zQSM-unsplash-2.webp" alt="Cloud solution">
<!--      <img class="img-fluid  " style="max-height: 300px; "-->
<!--           src="assets/images/hidefumi-ohmichi-gxfLSqIZFng-unsplash.jpg"-->
<!--           alt="Shop Converse">-->
    </div>
  </div>
</section>




<section class="container-fluid px-0 mb-n5">
  <div class="row g-0">
    <div class="col-md-6">
      <div class="d-block border-0 rounded-0 text-decoration-none py-md-4 bg-purple-light" href="#">
        <div class=" text-center">
          <mat-icon class="material-symbols-outlined icon-color-white-48" >forward_to_inbox</mat-icon>

          <h3 class="h5 mb-1 text-primary">support&#64;monochrome.bg</h3>
<!--          <p class="text-muted fs-sm"></p>-->
          <p i18n class="text-muted fs-sm">Have some questions? Feel free to contact us.</p>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <a class="d-block border-0 rounded-0 text-decoration-none py-md-4 bg-purple-light-1"
         target="_blank" routerLink="/about-seller">
        <div class=" text-center">
<!--          <img src="assets/icons/1161953_instagram_icon.svg"   />-->
          <!--          <mat-icon class="icon-color-white">photo</mat-icon>-->
          <!--          <i class="ci-instagram h3 mt-2 mb-4 text-primary"></i>-->
          <mat-icon class="icon-color-white-48" >store</mat-icon>
          <h3 i18n class="h5 mb-1">For Partners</h3>
          <p class="text-muted fs-sm"><span i18n>More details in link.</span></p>
<!--          <p class="text-muted fs-sm">&nbsp;</p>-->
        </div>
      </a>
    </div>
  </div>
</section>


<!--<div class="cs-cta-contact">-->
<!--  <div class="wrap">-->
<!--    <h3>Ready to transform the way your company uses Monochrome?</h3>-->
<!--    <p>Contact us today to take the first step toward power-->
<!--           automations that increase productivity and maximize ROI.</p>-->
<!--    <div class="button">-->
<!--      <a href="#" class="subscribe-btn drift-chat-trigger" data-drift="306979">Contact us</a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->





