import {Component, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {HomeService} from './home.service';
import {environment} from '../../environments/environment';
import {AppItemDetailsService} from '../app-item-details/app-item-details.service';
import {ItemView} from '../app-item/ItemView';
import {AppItemService} from '../app-item/app-item.service';
import {Subscription} from 'rxjs';
import {getLocaleId} from '@angular/common';
import {ItemVariation} from '../admin/admin-item/ItemVariation';
import {ItemColorVariation} from '../admin/admin-item/ItemColorVariation';
import {StoreService} from '../store/store.service';
import {Store} from '../store/Store';
import {AppStorageService} from '../storageService/app-storage.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeService]
})
export class HomeComponent implements OnInit, OnDestroy {
  addressImages = environment.apiEndpointImages;
  addressEndpoint = environment.apiEndpoint;

  elementsPerViewInCarousel: number;
  womenTshirts: ItemView[] = [];
  menTshirts: ItemView[] = [];
  itemTrainers: ItemView[] = [];
  currentLanguage = getLocaleId(this.locale);
  currentDomain = this.storageService.getDomainString();
  stores: Store[] = [];
  pageSizeHome = 12;
  pageIndex = 0;

  freeOrderLimit = 150;

  homeProductsDomainSubscription: Subscription;

  windowSmall_sm = 576;
  windowMedium_md	= 768;
  windowLarge_lg = 992;
  windowExtraLarge_xl	= 1200;

  constructor(
    private storageService: AppStorageService,
    private itemDetailsService: AppItemDetailsService,
    private appItemService: AppItemService,
    private storeService: StoreService,
    @Inject(LOCALE_ID) public locale: string
  ) {


  }

  ngOnInit(): void {

    this.onWindowResize();
    this.findItemsByPageName('w-blouse');
    this.findItemsByPageName('m-t-shirt');
    this.findItemsByPageName('boys-trainers');
    this.findAllStores();
  }
  ngOnDestroy(): void {
    if (this.homeProductsDomainSubscription) {
      this.homeProductsDomainSubscription.unsubscribe();
    }
  }

  // TODO
  findItemsByPageName(pageName: string) {
    this.homeProductsDomainSubscription = this.appItemService.findItemsByPageName(
      pageName, '', '', '', this.currentLanguage, 0, 11,
      null, [], [], 0, 750,
      '', false)
      .subscribe({
        next: pageWrapper => {

          if (pageName === 'w-blouse') {
            this.womenTshirts = pageWrapper.items;
            // this.itemsLoad = items;
            if (pageWrapper.pageableWrapper) {

              // this.totalElements = pageWrapper.pageableWrapper.totalElements;
            }
          }
          if (pageName === 'm-t-shirt') {
            this.menTshirts = pageWrapper.items;
          }
          if (pageName === 'boys-trainers') {
            this.itemTrainers = pageWrapper.items;
          }
          // this.onOrderBy(orderBy);
        },
        error: error => {
          console.error('There was an error!', error);
        }
      });
  }


  findAllStores() {
    this.storeService.findStoresByDomain(this.currentDomain, this.pageIndex, this.pageSizeHome)
      .subscribe({
        next: storeWrapper => {
          if (storeWrapper) {
            this.stores = storeWrapper.storeDTOList;
            // this.stores.push(storeWrapper.storeDTOList[0]);
            // this.stores.push(storeWrapper.storeDTOList[0]);
            // this.stores.push(storeWrapper.storeDTOList[0]);
            // this.stores.push(storeWrapper.storeDTOList[0]);
            // this.stores.push(storeWrapper.storeDTOList[1]);
            // this.stores.push(storeWrapper.storeDTOList[1]);
            // this.stores.push(storeWrapper.storeDTOList[1]);
            // this.stores.push(storeWrapper.storeDTOList[1]);
          }
        }, error: err => {
          console.log(err);
        }
      });
  }

  addElementOnEveryCircle(counterIdx: number) {

    const result =  (counterIdx ) % this.elementsPerViewInCarousel === 0;

    return result;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth < 576) {
      this.elementsPerViewInCarousel = 1;
    } else if (window.innerWidth < 768) {
      this.elementsPerViewInCarousel = 3;
    } else if (window.innerWidth < 992) {
      this.elementsPerViewInCarousel = 4;
    } else if (window.innerWidth < 1200) {
      this.elementsPerViewInCarousel = 6;
    } else {
      this.elementsPerViewInCarousel = 6;
    }
  }



  isThereSeveralPricesForItem(variations: ItemColorVariation[]) {
    let lowest;
    for (const variation of variations) {

      console.log(variation.itemSizeFormArray, '---', variations);
       lowest = this.appItemService.isThereSeveralPricesForItem(variation.itemSizeFormArray);
    }
    return lowest;
  }

  findLowestPriceForItem(variations: ItemColorVariation[]) {
    let lowest;
    for (const variation of variations) {

      console.log(variation.itemSizeFormArray, '---', variations);
      lowest = this.appItemService.findLowestPriceObjectForItem(variation.itemSizeFormArray);
    }
    return lowest;
  }

  findBiggestPriceForItem(variations: ItemColorVariation[]) {
    let lowest;
    for (const variation of variations) {

      console.log(variation.itemSizeFormArray, '---', variations);
      lowest = this.appItemService.findBiggestPriceForItem(variation.itemSizeFormArray);
    }
    return lowest;
  }


}
